import { useEffect, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import FormUsuarioExterno from "./FormUsuarioExterno";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import { Tag } from "primereact/tag";

const TableUsuariosExternos = ({ data, loading }) => {
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const [datosRegistro, setDatosRegistro] = useState(0);
  const [verSoloLectura, setVerSoloLectura] = useState(false);
  const { deleteRecurso } = useContext(NovedadesContext);

  const filters = {
    Legajo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Apellido: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Nombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Gerente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  const messageDelete = (e) => {
    return (
      <div>
        {e.Mail && (
          <>
            <Tag severity="warning" style={{ fontSize: "16px" }}>
              Deseas Eliminar al Usuario: {e.Mail}
            </Tag>
          </>
        )}
      </div>
    );
  };

  const onClickDeleteUser = (e) => {
    confirmDialog({
      message: () => messageDelete(e),
      header: "Eliminar Usuario",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => {
        deleteRecurso(e.IdRecurso);
      },
      onHide: () => console.log(),
      reject: () => console.log(),
    });
  };

  return (
    <div>
      <Tooltip target=".custom-target-icon" />
      <DataTable
        value={data}
        scrollable
        paginator
        loading={loading}
        className="p-datatable-customers"
        rows={15}
        dataKey="nroFreelance"
        filters={filters}
        filterDisplay="menu"
        emptyMessage="Sin resultados."
        style={{ fontFamily: "var(--main-font)" }}
      >
        <ConfirmDialog style={{ maxWidth: "0rem" }} />
        <Column
          sortable
          field="Legajo"
          header="Legajo"
          style={{ maxWidth: "9rem" }}
          filter
          filterField="Legajo"
          filterPlaceholder="Buscar por legajo"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Apellido"
          header="Apellido"
          style={{
            minWidth: "10rem",
            justifyContent: "start",
          }}
          filter
          filterField="Apellido"
          filterPlaceholder="Buscar por apellido"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Nombre"
          header="Nombre"
          style={{
            minWidth: "10rem",
            justifyContent: "start",
          }}
          filter
          filterField="Nombre"
          filterPlaceholder="Buscar por nombre"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Gerente"
          header="Gerente"
          style={{ minWidth: "12rem", justifyContent: "start" }}
          filter
          filterPlaceholder="Buscar por legajo"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          //body={estadoGerenteTemplate}
        />
        <Column
          sortable
          field="Mail"
          header="Email"
          style={{ minWidth: "6rem", maxWidth: "9rem" }}
          filter
          filterPlaceholder="Buscar por email"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          //filterElement={nominaFilterTemplate}
        />
        <Column
          style={{ minWidth: "6rem", maxWidth: "15rem" }}
          header="Acciones"
          bodyStyle={{ textAlign: "center" }}
          body={(rowData) => (
            <>
              <i
                className="pi pi-eye custom-target-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDatosRegistro(rowData);
                  setVerSoloLectura(true);
                  setDisplayUserModal(true);
                }}
                data-pr-tooltip="Ver"
                data-pr-position="top"
              ></i>
              <i
                className="pi pi-pencil custom-target-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDatosRegistro(rowData);
                  setVerSoloLectura(false);
                  setDisplayUserModal(true);
                }}
                data-pr-tooltip="Editar"
                data-pr-position="top"
              ></i>
              <i
                className="pi pi-trash custom-target-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onClickDeleteUser(rowData);
                  // setDatosRegistro(rowData);
                  // setDisplayHistorialModal(true);
                }}
                data-pr-tooltip="Eliminar"
                data-pr-position="top"
              ></i>
            </>
          )}
        />
      </DataTable>
      <FormUsuarioExterno
        setDisplayModal={setDisplayUserModal}
        displayModal={displayUserModal}
        datosRegistro={datosRegistro}
        verSoloLectura={verSoloLectura}
      />
    </div>
  );
};

export default TableUsuariosExternos;
