import React, { useState, useContext, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputSwitch } from "primereact/inputswitch";
import FieldCecos from "./FieldCecos";
import FieldProveedor from "./FieldProveedor";
import FileUploadControl from "./FileUploadControl";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { fechaHora } from "../helpers";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { validateTotUploadSize } from "../helpers/validateTotUploadSize";
import "../styles/formPagoProveedores.css";

const FormPagoProveedores = ({
  setDisplayPosition,
  displayPosition,
  position,
}) => {
  const { periodos, getPeriodos, addNovedad, proveedores } =
    useContext(NovedadesContext);
  const { username } = useContext(AuthContext);
  const [cecosArray, setCecosArray] = useState("");
  const [adjuntos, setAdjuntos] = useState([]);
  const [adjuntosFact, setAdjuntosFact] = useState([]);
  const [porcentajeTotal, setPorcentajeTotal] = useState();
  const [arrayCecos, setArrayCecos] = useState([]);
  const [agregarProv, setAgregarProv] = useState(false);
  const [razonSocial, setRazonSocial] = useState("");
  const [cuit, setCuit] = useState("");
  const [nuevoProv, setNuevoProv] = useState(null);
  const [filteredProveedores, setFilteredProveedores] = useState(null);
  const toast = useRef(null);

  const calcularPorcentaje = () => {
    let total = null;
    for (let i = 0; i < arrayCecos.length; i++) {
      total += arrayCecos[i].porcentaje;
    }
    setPorcentajeTotal(total);
  };

  const onHide = (name) => {
    handleClickOnHide(name);
    setDisplayPosition(false);
    if (name === "displayPosition") {
      setArrayCecos([]);
      formik.resetForm();
      setNuevoProv(null);
      setRazonSocial("");
      setCuit("");
      setAgregarProv(false);
      setAdjuntos([]);
      setAdjuntosFact([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayPosition") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayPosition"),
      reject: () => setDisplayPosition(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayPosition(true);
        } else {
          onHide("displayPosition");
        }
      },
    });
  };

  const errorPorcentaje = (valueCecosPorcentaje) => {
    if (valueCecosPorcentaje > 100) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "La suma de porcentajes de proyectos debe ser igual a 100",
        life: 5000,
      });
    }
  };

  useEffect(() => {
    calcularPorcentaje();
  }, [arrayCecos]);

  useEffect(() => {
    getPeriodos();
  }, []);

  const formik = useFormik({
    initialValues: {
      proveedor: null,
      periodo: "",
      importeTotal: null,
      freelance: false,
      facturaNro: "",
      comentarios: "",
    },
    validate: (data) => {
      if (nuevoProv) {
        data.proveedor = nuevoProv;
      }
      let errors = {};
      if (!data.proveedor) {
        errors.proveedor = "Campo requerido.";
      }
      if (!data.periodo) {
        errors.periodo = "Campo requerido.";
      }
      if (!data.importeTotal) {
        errors.importeTotal = "Campo requerido.";
      }
      return errors;
    },
    onSubmit: (data) => {
      if (!adjuntos.length && !adjuntosFact.length) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Debe adjuntar al menos un archivo",
          life: 5000,
        });
      } else if (porcentajeTotal < 100 || porcentajeTotal > 100) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "La suma de porcentajes de proyectos debe ser igual a 100",
          life: 5000,
        });
      } else {
        onHide("displayPosition");
        setAdjuntos([]);
        setAdjuntosFact([]);
        formik.resetForm();
        let array = [];
        arrayCecos.forEach((e) => {
          array.push({
            Porcentaje: e.porcentaje,
            Cecos: {
              IdCecos: e.id,
              Cliente: e.cliente,
              Proyecto: e.proyecto,
              Codigo: e.codigo,
            },
          });
        });
        addNovedad(
          {
            Tipo: "PP",
            Descripcion: "Pago a proveedor",
            Fecha: fechaHora().fecha,
            Hora: fechaHora().hora,
            Usuario: username,
            ProvNuevo: nuevoProv ? true : false,
            Proveedor: nuevoProv
              ? formik.values.proveedor
              : formik.values.proveedor.RazonSocial,
            Periodo: formik.values.periodo,
            DistribucionesStr: JSON.stringify(array),
            ImporteTotal: formik.values.importeTotal,
            FacturaNro: formik.values.facturaNro,
            Comentarios: formik.values.comentarios,
            Freelance: formik.values.freelance,
          },
          adjuntos,
          adjuntosFact
        );
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const searchProveedor = (event) => {
    setTimeout(() => {
      let _filteredProveedores;
      if (!event.query.trim().length) {
        _filteredProveedores = [...proveedores];
      } else {
        _filteredProveedores = proveedores.filter((p) => {
          return p.RazonSocial.toLowerCase().includes(
            event.query.toLowerCase()
          );
        });
      }
      setFilteredProveedores(_filteredProveedores);
    }, 250);
  };

  return (
    <Dialog
      draggable={false}
      header="Pago a proveedor"
      blockScroll={true}
      visible={displayPosition}
      position={position}
      modal
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
        fontFamily: "var(--main-font)",
      }}
      onHide={() => onHide("onHide")}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-pago-prov">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          {agregarProv ? (
            <FieldProveedor
              setNuevoProv={setNuevoProv}
              setAgregarProv={setAgregarProv}
              setCuit={setCuit}
              setRazonSocial={setRazonSocial}
              cuit={cuit}
              razonSocial={razonSocial}
              novedadTipo={"PP"}
            />
          ) : (
            <>
              <div className="p-fluid grid">
                {!nuevoProv ? (
                  <div className="input-container">
                    <span className="p-float-label">
                      <AutoComplete
                        id="proveedor"
                        dropdown
                        value={formik.values.proveedor}
                        suggestions={filteredProveedores}
                        completeMethod={searchProveedor}
                        field="RazonSocial"
                        forceSelection
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("proveedor"),
                        })}
                      />
                      <label
                        htmlFor="proveedor"
                        className={classNames({
                          "p-error": isFormFieldValid("proveedor"),
                        })}
                      >
                        Proveedor*
                      </label>
                    </span>
                    {getFormErrorMessage("proveedor")}
                  </div>
                ) : (
                  <div className="input-container">
                    <span className="p-float-label">
                      <InputText
                        id="proveedor"
                        name="proveedor"
                        disabled
                        value={nuevoProv}
                      />
                      <label htmlFor="proveedor">Nuevo Proveedor</label>
                    </span>
                  </div>
                )}
                <Button
                  icon="pi pi-plus-circle"
                  className="p-button-rounded p-button-text button-icon"
                  type="button"
                  tooltip="Nuevo proveedor"
                  tooltipOptions={{ position: "top" }}
                  onClick={() => setAgregarProv(true)}
                  aria-label="Agregar"
                />
                <div className="input-container small">
                  <span className="p-float-label">
                    <Dropdown
                      id="periodo"
                      name="periodo"
                      value={formik.values.periodo}
                      onChange={formik.handleChange}
                      options={periodos}
                      className={classNames({
                        "p-invalid": isFormFieldValid("periodo"),
                      })}
                    />
                    <label
                      htmlFor="periodo"
                      className={classNames({
                        "p-error": isFormFieldValid("periodo"),
                      })}
                    >
                      Período*
                    </label>
                  </span>
                  {getFormErrorMessage("periodo")}
                </div>
              </div>
              <FieldCecos
                cecosArray={cecosArray}
                setCecosArray={setCecosArray}
                calcularPorcentaje={calcularPorcentaje}
                arrayCecos={arrayCecos}
                setArrayCecos={setArrayCecos}
                errorPorcentaje={errorPorcentaje}
              />
              <div className="row-container porcentaje">
                <div className="input-container">
                  <span className="p-float-label">
                    <InputNumber
                      inputId="porcentaje"
                      value={porcentajeTotal}
                      autoComplete="off"
                      disabled
                    />
                    <label htmlFor="porcentaje">Porcentaje Total</label>
                  </span>
                </div>
                <div className="input-container importe">
                  <span className="p-float-label">
                    <InputNumber
                      minFractionDigits={2}
                      maxFractionDigits={3}
                      id="importeTotal"
                      value={formik.values.importeTotal}
                      onValueChange={formik.handleChange}
                      autoComplete="off"
                      className={classNames({
                        "p-invalid": isFormFieldValid("importeTotal"),
                      })}
                    />
                    <label
                      htmlFor="importeTotal"
                      className={classNames({
                        "p-error": isFormFieldValid("importeTotal"),
                      })}
                    >
                      Importe Neto*
                    </label>
                  </span>
                  {getFormErrorMessage("importeTotal")}
                </div>

                <div className="input-container switch">
                  <span>Freelance</span>
                  <InputSwitch
                    id="freelance"
                    checked={formik.values.freelance}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="row-container">
                <div className="input-container">
                  <span className="p-float-label">
                    <InputText
                      id="facturaNro"
                      autoComplete="off"
                      maxLength={30}
                      value={formik.values.facturaNro}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="facturaNro">Factura Número</label>
                  </span>
                </div>
              </div>
              <div className="row-container">
                <div className="input-container">
                  <span className="p-float-label">
                    <InputTextarea
                      id="comentarios"
                      maxLength={500}
                      autoResize
                      value={formik.values.comentarios}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                    <label htmlFor="comentarios">Comentarios</label>
                  </span>
                </div>
              </div>

              <div className="row-container">
                <div className="input-container">
                  <label className="control-title" htmlFor="adjuntosact">
                    Adjuntar facturas
                  </label>
                  <FileUploadControl
                    id="adjuntosFact"
                    name="adjuntosFact"
                    setAdjuntos={setAdjuntosFact}
                  />
                </div>
              </div>

              <div className="row-container">
                <div className="input-container">
                  <label className="control-title" htmlFor="adjuntosFact">
                    Adjuntar adicionales
                  </label>
                  <FileUploadControl
                    id="adjuntosFact"
                    name="adjuntosFact"
                    setAdjuntos={setAdjuntos}
                  />
                </div>
              </div>

              <div className="final-submit">
                <Button
                  type="submit"
                  label="GUARDAR"
                  disabled={!validateTotUploadSize(adjuntos)}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </Dialog>
  );
};

export default FormPagoProveedores;
